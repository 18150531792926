<template>
  <div class="models-monitor-select">
    <select name="monitor" :class="['models-monitor-select', model.idUserCollection && 'disabled']">
      <option value="">Seleccionar monitor</option>
      <option v-for="monitor in monitors" :key="monitor._id" :value="monitor._id" :selected="monitor._id === model.monitorId">
        {{ monitor.user }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: ["model", "location"],
  methods: {
    getmonitors: async function () {
      if (!this.$store.state.monitors) {
        this.loading = true;
        try {
          this.$store.state.monitors = "loading";
          const host = `${process.env.VUE_APP_API_HOST}admin/users?role=monitor&limit=100`;
          const { data } = await this.$api.get(`${host}`);
          this.$store.state.monitors = data.users;
        } catch (error) {
          this.$swal.fire({ title: "Error", text: error });
        }
        this.loading = false;
      }
    },
  },
  beforeMount: function () {
    this.getmonitors();
  },
  computed: {
    monitors: function () {
      return this.$store.state.monitors;
    },
  },
};
</script>

<style lang="scss">
.models-monitor-select {
  .disabled {
    pointer-events: none;
    opacity: 0.8;
    color: gray;
  }
}
</style>
